import React, { useState, useEffect } from 'react'
import './index.scss'
import bg from '../../static/news/head.png'

import Title from '../../Components/Title'

import http, { ROOT } from '../../utils/request'
import { useLocation } from 'react-router-dom'

export default function Connect() {

  let [txt, setTxt] = useState('')

  const location = useLocation()

  async function getData() {

    const { id } = location.state

    // const res = await http.post('/api/index/getArticleContent', { article_id: id })
    // if (res.code === 2001) {
    //   console.log(res.data.articlecontent);
    //   let txt = res.data.articlecontent
    //   setTxt(txt)
    // }
    console.log(id);
    if(id==10){
      let txt = '<p class="MsoNormal" style="margin-left:18.0pt"><span lang="EN-US" style="color:#A8D08D;mso-themecolor:accent6;mso-themetint:153">Create and Halliburton formally signed a cooperation agreement to develop and deploy an automated production line material monitoring and tracking system<o:p></o:p></span></p>'
      setTxt(txt)
    }
    if(id==11){
      let txt = '<p class="MsoNormal" style="margin-left:18.0pt"><span lang="EN-US" style="color:#A8D08D;mso-themecolor:accent6;mso-themetint:153">Create and China Railway First Group formally launched the implementation of construction site safety monitoring system<o:p></o:p></span></p>'
      setTxt(txt)
    }
    if(id==12){
      let txt = '<p class="MsoNormal" style="margin-left:18.0pt"><span lang="EN-US" style="color:#A8D08D;mso-themecolor:accent6;mso-themetint:153">Create and UPS reached a memorandum of understanding on the development and deployment of a fleet management system<o:p></o:p></span></p>'
      setTxt(txt)
    }
    if(id==9){
      let txt = '<p class="MsoNormal" style="margin-left:18.0pt"><span lang="EN-US" style="color:#A8D08D;mso-themecolor:accent6;mso-themetint:153">Create and Tuge Technology launched a global tracking solution for ships<o:p></o:p></span></p>'
      setTxt(txt)
    }
  }

  useEffect(() => {
    getData()
  }, []);

  return (
    <div className='news'>
      <div className='top-cont'>
        <img className='common-banner' src={bg} alt='#' />
        <div className='common-title'>——    Information Centre</div>
      </div>

      {/* <Title title='Information Centre' /> */}

      <div className='detail' dangerouslySetInnerHTML={{ __html: txt }}></div>


    </div>
  )
}
