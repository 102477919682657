import React, { useState } from 'react'
import './index.scss'

import SolutionCard from '../../Components/Solution'
import arrData from './data'
import { useLocation } from 'react-router-dom'

export default function Index() {

  const location = useLocation()
  // console.log(window.location.href);
  // let [txt, setTxt] = useState('')
  // window.scrollTo(0,0);
  if(location.state){
    console.log("000");
    const { id } = location.state;
  console.log("id",id);
 
  if(id==1){
 
  setTimeout(function(){
  
    window.scrollTo(0,1400);
    
},500)
  }
  if(id==2){
   
    setTimeout(function(){
       window.scrollTo(0,2600);
 },500)
    }
    if(id==3){
      
      setTimeout(function(){
        // window.location.href="#icon-03";
        window.scrollTo(0,3800);
   },500)
      }
      if(id==4){
        
        // // document.querySelector("").scrollIntoView({ behavior: 'smooth' })
       
        setTimeout(function(){
           window.scroll(0,5050);
            //  window.location.href="#icon-04";
        },500)
        }
        if(id==5){
          setTimeout(function(){
            // window.location.href="#icon-05";
            window.scroll(0,8650);
       },1000)
          }
          if(id==6){
            setTimeout(function(){
             
              window.scroll(0,9750);
         },1000)
            }

  }else{
    console.log("888");
    window.scroll(0,0);
  }

  let [oBanner, setOBanner] = useState({ pre: '10', cur: '01', nex: '02' });
    

  // document.documentElement.scrollTop="1200px";
  // console.log("123");
  let getNext = () => {
    let { cur } = oBanner
    cur = cur / 1 + 1 === 11 ? 1 : cur / 1 + 1
    let pre = oBanner.cur
    let nex = cur + 1 === 11 ? 1 : cur + 1
    console.log(
      pre,
      cur,
      nex,)
    setOBanner({
      cur: cur.toString().padStart(2, '0'),
      pre: pre.toString().padStart(2, '0'),
      nex: nex.toString().padStart(2, '0'),
    })
  }
  let getPrev = () => {
    let { cur } = oBanner
    cur = cur - 1 === 0 ? 10 : cur - 1
    let pre = cur - 1 === 0 ? 10 : cur - 1
    let nex = oBanner.cur
    setOBanner({
      cur: cur.toString().padStart(2, '0'),
      pre: pre.toString().padStart(2, '0'),
      nex: nex.toString().padStart(2, '0'),
    })
  }
 

  return (
    <div className='solution'>
      <div className='top-cont'>
        <img className='common-banner' src={require('../../static/solution/top-banner.png')} alt='#' />
        <div className='common-title'>Solutions</div>
      </div>
      <div className='banner'>
        {/* <div className='banner-line1'>车队管理软件</div>
        <div className='banner-line2'>车队所发生的一切，您都了如指掌</div>
        <div className='banner-line3'>Cartrack易于使用的平台使您能够通过强大的自动化和可操作的见解简化从作业管理到成本分析的整个运营。</div> */}
        <div className='banner-line1'>Diversified service platform</div>
        <div className='banner-line2'>You know everything about the team.</div>
        <div className='banner-line3'>Create's easy-to-use platform enables you to simplify the entire operation from job management to cost analysis with powerful automation and actionable insights.</div>
        <div className='banner-box'>

          <img className='banner-btn-prev' onClick={() => getPrev()} src={require('../../static/solution/prev.png')} alt='#' />
          <img className='banner-btn-next' onClick={() => getNext()} src={require('../../static/solution/next.png')} alt='#' />

          <div className='banner-item banner-prev'>
            <img className='banner-item-img' src={require(`../../static/solution/tit-${oBanner.pre}.png`)} alt='#' />
            <div className='banner-item-info'>
              {/* <div className='banner-info-tit'>About your team.......</div>
              <div className='banner-info-txt'>Everything your team needs is now in one place</div>
              <div className='banner-info-btn'>View details {`>`}</div> */}
              {/* <div className='banner-info-tit'>关于您车队.......</div>
              <div className='banner-info-txt'>您车队所需要的一切，现在都在一个地方</div>
              <div className='banner-info-btn'>查看详情 {`>`}</div> */}
            </div>
          </div>
          <div className='banner-item banner-curr'>
            <img className='banner-item-img' src={require(`../../static/solution/tit-${oBanner.cur}.png`)} alt='#' />
            <div className='banner-item-info'>
              <div className='banner-info-tit'>About your team.......</div>
              <div className='banner-info-txt'>Everything your team needs is now in one place</div>
              <div className='banner-info-btn'>
                <a className='a-href' href={`#icon-${oBanner.cur}`}>
                    {/* View details {`>`} */}
                    <div className='kong'></div>
                    <img className='images-go' src={require('../../static/solution/go.png')}></img>
                  </a>
                </div>
            </div>
          </div>
          <div className='banner-item banner-next'>
            <img className='banner-item-img' src={require(`../../static/solution/tit-${oBanner.nex}.png`)} alt='#' />
            <div className='banner-item-info'>
              {/* <div className='banner-info-tit'>About your team.......</div>
              <div className='banner-info-txt'>Everything your team needs is now in one place</div>
              <div className='banner-info-btn'>View details {`>`}</div> */}
            </div>
          </div>
        </div>
      </div>
      <div className='content'>
        {arrData.map(item => <SolutionCard data={item} key={item.title} />)}
      </div>
      <div className='core'>
        <div className='vertical-title'>Core business</div>
        <div className='core-content'>
          <div className='core-tit-1'>Core business</div>
          <div className='core-tit-2'></div>
          <img className='b-bg' src={require('../../static/solution/b-bg.png')} alt='#' />
          <div className='core-list'>
            <div className='core-list-item'>
              <div className='core-item-title'>
                <div className='core-item-title-num'>01</div>
                <div className='core-item-title-txt'>Hardware / OEM Sales</div>
              </div>
              <div className='core-item-cont'></div>
            </div>
            <div className='core-list-item'>
              <div className='core-item-title'>
                <div className='core-item-title-num'>02</div>
                <div className='core-item-title-txt'>Software License / SaaS</div>
              </div>
              <div className='core-item-cont'></div>
            </div>
            <div className='core-list-item'>
              <div className='core-item-title'>
                <div className='core-item-title-num'>03</div>
                <div className='core-item-title-txt'>Customized Solutions</div>
              </div>
              <div className='core-item-cont'></div>
            </div>
            <div className='core-list-item'>
              <div className='core-item-title'>
                <div className='core-item-title-num'>04</div>
                <div className='core-item-title-txt'>Professional Services / Support</div>
              </div>
              <div className='core-item-cont'></div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )




  










}
