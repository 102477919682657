import React, { useEffect, useState } from 'react'
import './index.scss'

import Title from '../../Components/Title'
import ProductList from '../../Components/Product'
import Production from '../../Components/Production'

import http, { ROOT } from '../../utils/request'

export default function Product() {
  window.scroll(0,0);
  // const arrTab = ['GNSS', 'DSM', 'VMS', 'INMARSAT', 'IMU', 'RTLS', 'BALS']
  const arrId = [2, 5, 6, 7] // not have bottom
  let [type, setType] = useState('max')
  let [selected, setSelected] = useState(0)
  function tabClick(index) {
    setSelected(index)
    setType(arrId.includes(arrTab[index].id / 1) ? 'min' : 'max')
    getProduct(arrTab[index].id)
  }
  // const arrProduct = [
  //   { idx: 1 },
  //   { idx: 2 },
  //   { idx: 3 },
  // ]

  let [arrProduct, setArrProduct] = useState([])

  let [arrTab, setArrTab] = useState([])
  async function getTaps() {
    // const res = await http.post('/api/index/getType')
    // if (res.code === 2001) {
    //   let ctx = res.data;
    //   console.log(ctx);
    //   setArrTab(ctx);
    //   console.log(ctx);
    //   getProduct(ctx[0]?.id)
    // }
    let ctx = [{ id: 1, name: 'GNSS' }, { id: 2, name: 'DSM' }, { id: 3, name: 'VMS' }, { id: 4, name: 'INMARSAT' }, { id: 5, name: 'IMU' },
    { id: 6, name: 'RTLS' }, { id: 7, name: 'BALS' }];
    console.log(ctx);
    setArrTab(ctx);
    console.log(ctx);
    getProduct(ctx[0]?.id)
  }

  async function getProduct(type_id) {
    console.log(type_id);
    // const res = await http.post('/api/index/getProduct', { type_id })
    // if (res.code === 2001) {
    //   let ctx = res.data;
    //   console.log('998', res.data)
    //   setArrProduct(ctx)
    // }
    if (type_id == 1) {
      let ctx = [{mainlyApplicable:" Can be widely used in the command and dispatch system of various industries; special anti-theft systems for the car rental industry and outdoor machinery and equipment; use in large and medium-sized engineering vehicles; use in logistics cargo tracking.",name: 'High Precision Vehicle/Vessel Tracking Device',productimages: ['1.png'],producttext:'<p style="line-height: 1.8;">VE400 vehicle/vessel positioning terminal is a<b style="color: rgb(239, 198, 49);"> multi-system centimeter-level</b> high-precision positioning terminal independently designed and developed by Create Technologies, which supports inertial navigation and positioning. Compatible with all GNSS systems, including <span style="color: rgb(239, 198, 49); font-weight: bold;">GPS, GLONASS, BDS, QZSS.</span> It has the characteristics of high sensitivity, anti-interference and high performance. The<span style="color: rgb(239, 198, 49); font-weight: bold;"> long-baseline RTK algorithm</span> is used to ensure a single base station coverage of 45,000 square kilometers, within a radius of 120 kilometers to achieve centimeter-level precision positioning and second-level positioning convergence delay.</p><p style="line-height: 1.8;"><br></p><p style="line-height: 1.8;">In the future, through the internal<span style="color: rgb(239, 198, 49); font-weight: bold;"> integrated inertial navigation module</span>, deep integration of GNSS and inertial navigation will be implemented to achieve seamless connection of <span style="color: rgb(239, 198, 49); font-weight: bold;">indoor and outdoor </span>high-precision positioning</p>',sort:0,},
    {name:"High Precision Personnel Tracking Device",mainlyApplicable:"Can be widely used in various industries command and dispatch system, suitable for port, airport, power station and chemical industrial park and logistics center personnel tracking management",productimages:['2.png'],producttext:'<p style="line-height: 1.8;">The CP200 personnel positioning terminal is a <span style="color: rgb(239, 198, 49); font-weight: bold;">multi-system centimeter-level</span> high-precision positioning terminal independently designed and developed by Create Technologies, which supports inertial navigation and positioning. Compatible with all GNSS systems, including <span style="font-weight: bold; color: rgb(239, 198, 49);">GPS, GLONASS, BDS, QZSS</span>. It has the characteristics of high sensitivity, anti-interference and high performance. The<span style="color: rgb(239, 198, 49); font-weight: bold;"> long-baseline RTK algorithm</span> is used to ensure a single base station coverage of 45,000 square kilometers, within a radius of 120 kilometers to achieve centimeter-level precision positioning and second-level positioning convergence delay.</p><p style="line-height: 1.8;"><br></p><p style="line-height: 1.8;">It can also be connected to the<span style="color: rgb(239, 198, 49); font-weight: bold;"> external inertial navigation module</span> through the internal integrated Bluetooth module to perform the deep integration of GNSS and inertial navigation to realize the seamless connection of<span style="color: rgb(239, 198, 49); font-weight: bold;"> indoor and outdoor</span> high-precision positioning</p>'},
    {name:"Vehicle Tracking Device",productimages:['3.png'],sort:10,mainlyApplicable:"Can be widely used in the command and dispatch system of various industries; special anti-theft systems for the car rental industry and outdoor machinery and equipment; use in large and medium-sized engineering vehicles; use in logistics cargo tracking.",producttext:'<p style="line-height: 1.8;">VE800-ST vehicle positioning terminal is a<span style="color: rgb(239, 198, 49); font-weight: bold;"> multi-system sub-meter </span>high-precision positioning terminal independently designed and developed by Create Technologies, which supports inertial navigation and positioning. <span style="color: rgb(239, 198, 49); font-weight: bold;">Compatible with GPS and BDS</span> dual systems. It has the characteristics of high sensitivity, anti-interference and high performance.&nbsp;</p><p style="line-height: 1.8;"><br></p><p style="line-height: 1.8;">Adopting <span style="color: rgb(239, 198, 49); font-weight: bold;">GNSS/INS integrated navigation </span>and positioning technology to provide high-precision vehicle positioning and navigation functions, without the need for odometer or speed signal access and no installation requirements, it can provide high-precision positioning for vehicles in tunnels, garages and other environments.</p>'},
    {name:"Personnel Tracking Device",sort:100,productimages:["4.png"],mainlyApplicable:"smart campus, elderly care, tourism, field staff management (forestry, sanitation, security, etc.)",producttext:'<p style="line-height: 1.8;">The CP300 is a card-type positioning terminal that provides users with “location + communication” service. It is equipped with high-precision <b style="color: rgb(239, 198, 49);">GPS+BDS</b> positioning module, <span style="color: rgb(239, 198, 49); font-weight: bold;">combined with GSM ® mobile communication, A-GNSS, Wi-Fi, gravity sensing </span>and other technologies.&nbsp;</p><p style="line-height: 1.8;"><br></p><p style="line-height: 1.8;">Support <span style="font-weight: bold; color: rgb(239, 198, 49);">voice reminder, SOS rescue, electronic fence, history track, remote shutdown </span>and other functions.</p>'},
    {name:"Vessel Tracking Device",productimages:['5.png'],mainlyApplicable:"Can be widely used on fishing boats, tugboats, oil tankers, bulk carriers and other small and medium-sized ships in rivers and offshore areas.",producttext:'<p style="line-height: 1.8;">VE450/451 vessel positioning terminal is a<b style="color: rgb(239, 198, 49);"> multi-system</b> high-precision positioning terminal independently designed and developed by Create Technologies, which supports inertial navigation and positioning. Compatible with all GNSS systems, including <span style="font-weight: bold; color: rgb(239, 198, 49);">GPS, <span style="font-family: &quot;Source Sans Pro&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; font-size: 13px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; float: none; display: inline !important;">BDS</span></span>. It has the characteristics of high sensitivity, anti-interference and high performance.&nbsp;</p><p style="line-height: 1.8;"><br></p><p style="line-height: 1.8;">Supports <span style="color: rgb(239, 198, 49); font-weight: bold;">4G</span> and<span style="font-weight: bold; color: rgb(239, 198, 49);"> Iridium</span> communications</p>'},
    {name:"Intelligent Safety Helmet (Position + Video)",productimages:["6.png"],mainlyApplicable:"Can be widely used in construction, port, factory and other industries",producttext:'<p style="line-height: 1.8;">CA04 adopts low power consumption, integrated design, built-in voice broadcasting, <span style="color: rgb(239, 198, 49); font-weight: bold;">GPS, integrated HD wide-angle camera</span>. Supports real-time voice intercom, video surveillance, and real-time location functions.&nbsp;</p><p style="line-height: 1.8;"><br></p><p style="line-height: 1.8;">The customized service platform gathers all data resources to realize data sharing and interconnection between systems; In-depth analysis, generate analysis results, use accurate data analysis, assist safety production management decisions; Intelligent warning, dynamic risk warning. Electronic map shows personnel, equipment, events, regional information, unified coordination, one-click command and scheduling</p>'},
      {name:"Deformation Monitoring Device",productimages:["7.png"],mainlyApplicable:"DUBHE.CR® Deformation monitoring system is is suitable for bridges, dams, tall buildings, landslides and other objects that require health monitoring to prevent major losses and casualties",producttext:'<p style="line-height: 1.8;">DUBHE.CR® is a millimeter-level real-time deformation monitoring terminal independently designed and developed by Create Technologies Pte Ltd.</p><p style="line-height: 1.8;"><br></p><p style="line-height: 1.8;">Multi-system dual-frequency GNSS module is used to collect satellite data provided by Beidou, <span style="color: rgb(239, 198, 49); font-weight: bold;">GPS and other satellite positioning systems to suppress multipath effects</span>. Combining a stable phase center (less than 0.8mm) to suppress radio frequency interference makes the system more reliable.</p>"'},
{name:"MIZAR® Cloud Based Software Platform",productimages:["8.png"],mainlyApplicable:'',producttext:'<p style="line-height: 1.8;">1. Visualizes the real-time deformation monitoring.</p><p style="line-height: 1.8;">2. The system can collect and process the deformation data continuously, automatically and in real time</p><p style="line-height: 1.8;">3. According to the processing data, the control center realizes analysis, process line drawing, judgment, prediction, report printing and Web publishing.</p><p style="line-height: 1.8;">4. According to the relevant standards and experience data, the warning value is set for the deformed body to realize the over-limit alarm function.</p>'},
{name:"SIRUS® Software Platform ",productimages:["9.png"],mainlyApplicable:"",producttext:'<p style="line-height: 1.8;">1.Visualizes the real-time location of personnel and vehicles.&nbsp;</p><p style="line-height: 1.8;">2. Automatically generate tracking information and log traffic information.</p><p style="line-height: 1.8;">3. Visually display the statistics of vehicles in the certain thru Geo fencing.</p><p style="line-height: 1.8;">4. Tracing the historical data of a vehicle route</p><p style="line-height: 1.8;">5. Alert on route notification</p><p style="line-height: 1.8;">6.Automatic generation of various system information and security warning information</p><p style="line-height: 1.8;">7. Compatibility of EELINK,JT808 and other main communication protocol for GNSS Tracking device</p><p style="line-height: 1.8;">8.Customizable vehicle tracking and management tasks to meet the user vehicle management rules</p>'},


    ];
      // console.log('998', res.data);
      // console.log("666",JSON.parse(ctx));
      setArrProduct(ctx)
    }
    if (type_id == 2) {
      let ctx = [{
        name:"MXVR4104",
        productimages:["10.png"],
        mainlyApplicable:'',
        producttext:'<div style="line-height: 1.8;">1. Real-time monitoring of vehicle location and running path</div><div style="line-height: 1.8;">2. Record vehicle speed, trajectory, acceleration, and other abnormal driving behavior and alert</div><div style="line-height: 1.8;">3. Establish two-way data communication between the management portal and drivers to collect data, broadcast information and other functions.</div><div style="line-height: 1.8;">4. AI algorithms are used to monitor drivers behaviors and provide multiple alarm means for violations to ensure drivers personal safety</div><div style="line-height: 1.8;">5. The data platform provides big data analysis and produces reports on the risk characteristics of drivers</div>',
      },{
        name:"CDMS-813",
        productimages:["11.png"],
        producttext:'"<div style="line-height: 1.8;">1. Real-time monitoring of vehicle location and running path</div><div style="line-height: 1.8;">2. Record vehicle speed, trajectory, acceleration, and other abnormal driving behavior and alert</div><div style="line-height: 1.8;">3. Establish two-way data communication between the management portal and drivers to collect data, broadcast information and other functions.</div><div style="line-height: 1.8;">4. AI algorithms are used to monitor drivers behaviors and provide multiple alarm means for violations to ensure drivers personal safety</div><div style="line-height: 1.8;">5. The data platform provides big data analysis and produces reports on the risk characteristics of drivers</div>'
      ,
      mainlyApplicable:""
      }
    
    ];
      setArrProduct(ctx)
    }
    if (type_id == 3) {
      let ctx = [{
        name:"Integrated Vessel Monitoring System",
        productimages:["12.png"],
        producttext:'<div style="line-height: 1.8;">MXVR4105 is the new generation of mobile video recorder, it uses H.265 technology and the advantages are lowering the transmission bandwidth and saving the storage. <span style="color: rgb(239, 198, 49); font-weight: bold;">It can support 1080P high-definition real-time recording</span>, real-time vehicle location tracking and monitoring and the face detection.&nbsp;</div><div style="line-height: 1.8;"><br></div><div style="line-height: 1.8;">All the information such as GPS and video can be uploaded via wireless network: <span style="color: rgb(239, 198, 49); font-weight: bold;">3G/4G/WIFI.</span> It has already passed EN50155/ISO16750 in order to meet the requirements of mobile usage. This device can be used in many kinds of solutions for different applications.</div><div style="line-height: 1.8;"><br></div><div style="line-height: 1.8;"><img src="https://w2.create-tech.com.sg/a.png" style="width: 100%;" data-filename="filename"><br></div><div style="line-height: 1.8;"><br></div>',
mainlyApplicable:'<p style="line-height: 1.5;">1.Support penta-brid (HDCVI/AHD/TVI/CVBS/IP) video input&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;2.Support H.265/smart H.265 video compression&nbsp; &nbsp;</p><p style="line-height: 1.5;">3.Support 4 channels 1080P real-time recording&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;4.VGA/TV simultaneous video output&nbsp; &nbsp;</p><p style="line-height: 1.5;">5.Support 1 HDD and 1 SD card&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 6.Support face detection&nbsp; &nbsp;</p><p style="line-height: 1.5;">7.Aviation connectors adopted&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;8.Multiple network monitoring: Web viewer, CMS(DSS/PSS) &amp; DSMS</p>',


      }
    
    ];
      setArrProduct(ctx)
    }
    if (type_id == 4) {
      let ctx = [
        {
          mainlyApplicable:'<div>1.Standard IP data communication&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;2. Built-in proxy server, and support BGAM IP voice communication function&nbsp; &nbsp;</div><div>3. Supporting SMS and Email functions&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 4. Supporting passive GNSS location&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</div><div>5. Supporting PPPoE and NAT Internet access&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;6. Built-in Web Server, supporting Web control&nbsp; &nbsp;&nbsp;</div><div>7. Built-in firewall&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;8. Providing Wi-Fi, supporting wireless access&nbsp; &nbsp;</div><div>9. Providing Android and iOS APP and application functional&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 10. Supporting backup and recovery of system setting&nbsp; extension&nbsp; &nbsp;</div>',
name:"Shipborne Satellite Broadband Terminal ",
producttext:'<div style="line-height: 1.8;">The FleetOne shipborne satellite broadband terminal FT-150 which is based on the <span style="color: rgb(239, 198, 49); font-weight: bold;">Inmarsat I-4 satellite communication</span> system that can provide up to 150kbps data service bandwidth and support clear voice, stable file transmission, short message service,.</div><div style="line-height: 1.8;"><br></div><div style="line-height: 1.8;">The terminal is connected with the Public Switched Telephone Network (PSTN), <span style="color: rgb(239, 198, 49); font-weight: bold;">mobile communication (3G/4G/5G)</span> and the Internet via the Inmarsat I-4 satellite communication system to support image transmission, transoceanic communication, maritime navigation, distress rescue, marine disaster alerts, track management, vessel tracking, etc. without direct access to the ground network.</div>',
productimages:['13.png']
        }
      ];
      setArrProduct(ctx)
    }
    if (type_id == 5) {
      let ctx = [
        {
name:"Inertial Measurement Unit ",
mainlyApplicable:'',
producttext:'<div style="line-height: 1.8;">The advantage of inertial navigation is that it requires no external&nbsp;</div><div style="line-height: 1.8;">references in order to determine its position, orientation, or&nbsp;</div><div style="line-height: 1.8;">velocity once it has been initialized.&nbsp;</div><div style="line-height: 1.8;">Since it requires no external reference (after initialization)</div><div style="line-height: 1.8;">it&nbsp; is immune to jamming and deception. It is widely used</div><div style="line-height: 1.8;">in military and civilian areas</div><div><br></div><div><br></div><div><img src="https://w2.create-tech.com.sg/c.png" style="width: 100%;" data-filename="filename"><br></div><div><br></div>',
productimages:['14.png']
        },
        {
          name:"Personnel Positioning System",
          mainlyApplicable:'',
          productimages:["15.png"],
          producttext:'<p><img src="https://w2.create-tech.com.sg/b.png" style="width: 100%;" data-filename="filename"><br></p>',

        }
      ];
      setArrProduct(ctx);
    }
    if (type_id == 6) {
      let ctx = [
        {
          name:"Positioning Server",
          mainlyApplicable:'',
          productimages:["16.png"],
          producttext:'<div style="line-height: 1.8;">1. Patented UWB Sparse Pulse Technology</div><div style="line-height: 1.8;">2. High Position Accuracy up to 1 cm</div><div style="line-height: 1.8;">3. Support 1D/2D/3D Positioning Method</div><div style="line-height: 1.8;">4. High Real-Time Capability, Positioning Delay &lt; 10 ms</div><div style="line-height: 1.8;">5. Not sensitive to target movement speed</div><div style="line-height: 1.8;">6. Enhanced antenna cover up to 200 meters</div><div style="line-height: 1.8;">7. Long Battery Life</div><div style="line-height: 1.8;">8. Huge System Capacity to support up to 10,000 user</div><div style="line-height: 1.8;">9. Maximum refresh rate is 12KHz</div>',

        },
        {
          name:"Base Station",
          mainlyApplicable:'',
          productimages:["17.png"],
          producttext:'<div style="line-height: 1.8;">1. Patented UWB Sparse Pulse Technology</div><div style="line-height: 1.8;">2. High Position Accuracy up to 1 cm</div><div style="line-height: 1.8;">3. Support 1D/2D/3D Positioning Method</div><div style="line-height: 1.8;">4. High Real-Time Capability, Positioning Delay &lt; 10 ms</div><div style="line-height: 1.8;">5. Not sensitive to target movement speed</div><div style="line-height: 1.8;">6. Enhanced antenna cover up to 200 meters</div><div style="line-height: 1.8;">7. Long Battery Life</div><div style="line-height: 1.8;">8. Huge System Capacity to support up to 10,000 user</div><div style="line-height: 1.8;">9. Maximum refresh rate is 12KHz</div>',

        },
        {
          name:"Wearable Badge Tag",
          mainlyApplicable:'',
          producttext:'<div style="line-height: 1.8;">1. Patented UWB Sparse Pulse Technology</div><div style="line-height: 1.8;">2. High Position Accuracy up to 1 cm</div><div style="line-height: 1.8;">3. Support 1D/2D/3D Positioning Method</div><div style="line-height: 1.8;">4. High Real-Time Capability, Positioning Delay &lt; 10 ms</div><div style="line-height: 1.8;">5. Not sensitive to target movement speed</div><div style="line-height: 1.8;">6. Enhanced antenna cover up to 200 meters</div><div style="line-height: 1.8;">7. Long Battery Life</div><div style="line-height: 1.8;">8. Huge System Capacity to support up to 10,000 user</div><div style="line-height: 1.8;">9. Maximum refresh rate is 12KHz</div>',
          productimages:["18.png"]
        },{
          name:"Vehicle Tag & Ranging Station",
          mainlyApplicable:'',
          producttext:'<div style="line-height: 1.8;">1. High Position Accuracy up to 20 cm&nbsp;</div><div style="line-height: 1.8;">2. Support 1D/2D/3D Positioning Method&nbsp;</div><div style="line-height: 1.8;">3. 100 meters Real-time monitoring range in radius&nbsp;</div><div style="line-height: 1.8;">4. Not sensitive to target movement speed&nbsp;</div><div style="line-height: 1.8;">5. Long Battery Life 6.Huge System Capacity to support up to 6,500 Tags</div>',
          productimages:['19.png']
        },{
          name:"Audio-Visual Alert Device",
          mainlyApplicable:'',
          producttext:'<div style="line-height: 1.8;"><div style="line-height: 1.8;">Fixed installation on the vehicle</div><div style="line-height: 1.8;">Provides audible and flashing alarms</div></div>',
          productimages:['20.png']
        },{
          name:'Anti- Collision Tag',
          mainlyApplicable:'',
          producttext:'<div style="line-height: 1.8;"><div style="line-height: 1.8;">1. centimeter-level ranging accuracy&nbsp;</div><div style="line-height: 1.8;">2. Real-time monitoring range of 100 meters radius&nbsp;</div><div style="line-height: 1.8;">3. Insensitive to target movement speed&nbsp;</div><div style="line-height: 1.8;">4. Long battery life&nbsp;</div><div style="line-height: 1.8;">5. Provides stationary monitoring and vibration alarm</div></div>',
          productimages:["21.png"]
        },{
          name:"Anti- Collision Badge",
          mainlyApplicable:'',
          productimages:["22.png"],
          producttext:'<div style="line-height: 1.8;"><div style="line-height: 1.8;">1. centimeter-level ranging accuracy&nbsp;</div><div style="line-height: 1.8;">2. Real-time monitoring range of 100 meters radius&nbsp;</div><div style="line-height: 1.8;">3. Insensitive to target movement speed&nbsp;</div><div style="line-height: 1.8;">4. Long battery life&nbsp;</div><div style="line-height: 1.8;">5. Provides stationary monitoring and vibration alarm</div></div>',

        }
      ];
      setArrProduct(ctx);
    }
    if (type_id == 7) {
      console.log("999");
      let ctx = [
        {
          name:"Base Station",
          mainlyApplicable:'',
          productimages:["23.png"],
          producttext:'<div style="line-height: 1.8;">1. Using BLE AOA/AOD Technology</div><div style="line-height: 1.8;">2. Position Accuracy up to 30 cm</div><div style="line-height: 1.8;">3. Support 1D/2D/3D Positioning Method</div><div style="line-height: 1.8;">4. Not sensitive to target movement speed</div><div style="line-height: 1.8;">5. Enhanced antenna cover up to 200 meters</div><div style="line-height: 1.8;">6. Long Battery Life &gt; 3 years</div>',

        },
        {
          name:'"Wearable Badge Tag"',
          mainlyApplicable:'',
          productimages:["24.png"],
          producttext:'<div style="line-height: 1.8;">1. Using BLE AOA/AOD Technology</div><div style="line-height: 1.8;">2. Position Accuracy up to 30 cm</div><div style="line-height: 1.8;">3. Support 1D/2D/3D Positioning Method</div><div style="line-height: 1.8;">4. Not sensitive to target movement speed</div><div style="line-height: 1.8;">5. Enhanced antenna cover up to 200 meters</div><div style="line-height: 1.8;">6. Long Battery Life &gt; 3 years</div>',

        },{
          name:"Positioning Server",
          producttext:'<div style="line-height: 1.8;">1. Using BLE AOA/AOD Technology</div><div style="line-height: 1.8;">2. Position Accuracy up to 30 cm</div><div style="line-height: 1.8;">3. Support 1D/2D/3D Positioning Method</div><div style="line-height: 1.8;">4. Not sensitive to target movement speed</div><div style="line-height: 1.8;">5. Enhanced antenna cover up to 200 meters</div><div style="line-height: 1.8;">6. Long Battery Life &gt; 3 years</div>',
          productimages:["25.png"],
          mainlyApplicable:''
        },{
          name:"Wearable Tracking Tag",
          productimages:["26.png"],
          producttext:'<div style="line-height: 1.8;"><div style="line-height: 1.8;">1.&nbsp; High Position Accuracy up to 1 meter</div><div style="line-height: 1.8;">2. Support 1D/2D/3D Positioning Method</div><div style="line-height: 1.8;">3. High Real-Time Capability, Positioning Delay &lt; 10 ms</div><div style="line-height: 1.8;">4. Not sensitive to target movement speed</div><div style="line-height: 1.8;">5. Long Battery Life</div></div>',
          mainlyApplicable:''
        },{
          name:"Standard Tracking Tag",
          productimages:["27.png"],
          producttext:'<div style="line-height: 1.8;"><div style="line-height: 1.8;"><div style="line-height: 1.8;">1.&nbsp; High Position Accuracy up to 1 meter</div><div style="line-height: 1.8;">2. Support 1D/2D/3D Positioning Method</div><div style="line-height: 1.8;">3. High Real-Time Capability, Positioning Delay &lt; 10 ms</div><div style="line-height: 1.8;">4. Not sensitive to target movement speed</div><div style="line-height: 1.8;">5. Long Battery Life</div></div></div>',
mainlyApplicable:''
        }
      ];
      setArrProduct(ctx);
    }
  }



  useEffect(() => {
    getTaps()

  }, [])

  return (
    <div className='product'>
      <div className='top-cont'>
        <img className='common-banner' src={require('../../static/solution/top-banner.png')} alt='#' />
        <div className='common-title'>Product Center</div>
      </div>
      <Title title='Product Center' />
      <div className='tab'>
        {arrTab.map((item, index) => {
          return (
            <div
              key={item.id}
              className={selected === index ? 'tab-item tab-item-selected' : 'tab-item'}
              onClick={() => tabClick(index)}
            >{item.name}</div>
          )
        })}
      </div>

      {/* {arrProduct.map((v, i) => <ProductList key={v.name} idx={i} data={v} />)} */}
      {arrProduct.map((v, i) => <Production key={v.name} type={type} idx={i} data={v} />)}

    </div>
  )
}
