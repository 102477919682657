import React, { useState, useEffect } from 'react'
import './index.scss'
import bg from '../../static/about-bg.png'
import { useNavigate } from 'react-router-dom'

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, FreeMode } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import Title from '../../Components/Title'
import Client from '../../Components/Clinet'

import http, { ROOT } from '../../utils/request'
import { Link } from 'react-router-dom';

export default function About() {
  window.scroll(0,0);
  let [selected, setSelected] = useState(-1)
  // const arrLevel = [
  //   { post: 'CEO', name: '葛卫锋' },
  //   { post: 'CTO', name: 'Andy' },
  //   { post: '技术顾问', name: '汪卫国博士' },
  //   { post: '技术顾问', name: '天问一号总设计师张荣桥博士' },
  // ]

  let [arrLevel, setLevel] = useState([])

  let [context, setContext] = useState('')

  async function getData() {
    // const res = await http.post('/api/index/showContent')
    // if (res.code === 2001) {
    //   let ctx = res.data?.text?.showcontent || ''
    //   setContext(ctx);
    //   console.log("999",res);
      
    //   let arrlv = res.data?.team.map(v => {
    //     return {
    //       post: v.zhiwei,
    //       name: v.name,
    //       img: ROOT + v.headimage
    //     }
    //   }) || []
    //   setLevel(arrlv)
    // }
    const res={data:{team:[]}};
    res.data.team=[{name: 'Frank Ge', headimage: '01.png', zhiwei: 'CEO', sort: 11},
    {name: 'Guoquan Zeng', headimage: '02.png', zhiwei: 'CTO', sort: 9},
    {name: 'Rongqiao Zhang', headimage: '03.png', zhiwei: 'Technical Advisor', sort: 8},
    {name: 'Andy Wang', headimage: '04.png', zhiwei: 'CFO', sort: 7},
    {name: 'Renee Hou', headimage: '05.png', zhiwei: 'Marketing Manager', sort: 1}
  ];
  let arrlv = res.data?.team.map(v => {
        return {
          post: v.zhiwei,
          name: v.name,
          img: v.headimage
        }
      }) || []
      setLevel(arrlv)
  }
  const navigate = useNavigate()
  let getDetail = id => {
    navigate('/detail', {
      state: { id }
    })
  }

  useEffect(() => {
    getData()
  }, []);


  return (
    <div className='about'>
      <div className='top-cont'>
        <img className='common-banner' src={bg} alt='#' />
        <div className='common-title'>About us</div>
      </div>

      <Title title='Our philosophy' />

      <div className='philosophy'>
        <div className='cs'>
        <img className='philosophy-img' src={require('../../static/ph-1.png')} alt='' />
        <div className='big-meng'>
          <div className='meng' ></div>
        <div className="tips" >VISION</div>
        <div className="tips1">Pioneer and technology leader in </div>
        <div className="tips2">precise tracking positioning and </div>
        <div className="tips3">deformation monitoring market.</div></div>
        </div>

        <div className='cs'>
        <img className='philosophy-img' src={require('../../static/ph-2.png')} alt='' />
      <div className='big-meng'>
      <div className='meng'></div>
        <div className="tips">Value</div>
        <div className="tips1">Customer oriented, Innovation  </div>
        <div className="tips2">drives excellence </div>

      </div>
       
        </div>

        <div className='cs'>
        <img className='philosophy-img' src={require('../../static/ph-3.png')} alt='' />
        <div className='big-meng'>
        <div className='meng'></div>
        <div className="tips">Mission</div>
        <div className="tips1">Bringing to the market innovative</div>
        <div className="tips2">and cost effective products and</div>
<div className="tips3">asolutions, delivering values to </div>
<div className="tips4">industrial safety, asset and personnel </div>
<div className="tips5">tracking, fleet management application </div>
<div className="tips6">areas and
beyond. </div>

        </div>


       
        </div>
       
      
       
      </div>

      <div className='map'>
        <div className='zi'>HEADQUARTER IN SINGAPORE</div>
        <img className='map-bg' src={require('../../static/map.png')} alt='' />
        <div className='map-cont'>
          {/* {context} */}
          Create Technologies Pte Ltd was founded in Singapore 2017 by Frank Ge, CEO and onboard Dr. Zeng Guoquan as CTO and Mr. Huai Zhaihui as COO. Create, Build and Design on all aspects (inclusive owned IP ) of the hardware, software platform and service developments. Since 2020, we rolled out the leading Precise Tracking and Positioning Products and Solutions into the market.
          <br />
          <br />
          Create has established a joint laboratory with Tongji University to develop high-precision satellite positioning algorithms.
Hardware equipment manufacturing in Shenzhen, Shanghai,nanjing,China.

          <br />
          <br />
          {/* Hardware equipment manufacturing in Shenzhen, shanghai,nanjing,China. */}
        </div>
        <div className='map-more'>
          See more
        </div>
      </div>

      <Title title='Core Team' />

      <div className='team'>
        <Swiper
          className='team-box'
          slidesPerView={4}
          spaceBetween={30}
          freeMode={true}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          modules={[Navigation, Autoplay, FreeMode]}
        >
          {arrLevel.map((item, index) => (
            <SwiperSlide key={index}>
              <div
                className='team-img-box'
                onMouseOver={() => setSelected(index)}
                onMouseOut={() => setSelected(-1)}
              >
                {/* <img className='team-img' src={require(`../../static/team-${index + 1}.jpg`)} alt='' /> */}
                <img className='team-img' src={require("../../static/lunbo/" + item.img)} alt='' />
                {selected === index ? (
                  <div className='team-level-hover'>
                    <img className='team-level-hover-bg' src={require('../../static/team-bg.png')} alt='' />
                    <div className='team-level-hover-value'>
                      <div>{item.post}</div>
                      <div className='team-level-hover-division'>|</div>
                      <div className='team-level-hover-name'>{item.post} - {item.name}</div>
                    </div>
                  </div>
                ) : (
                  <div className='team-level'>
                    <div className='team-level-value'>{item.post}</div>
                    <div className='team-level-name'>{item.name}</div>
                  </div>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <img className='before swiper-button-prev' src={require('../../static/before.png')} alt='' />
        <img className='after swiper-button-next' src={require('../../static/after.png')} alt='' />

      </div>

      <Title title='News update' />

      <div className='news'>
        <div className='news-item' onClick={() => { getDetail(10) }}>
          <img className='news-img' src={require('../../static/news-1.jpg')} alt='' />
          <div className='news-tit'>RTLS Indoor tracking System</div>
          <div className='news-txt'>Create and Halliburton formally signed a cooperation agreement to develop and deploy an automated production line material monitoring and tracking system</div>
        </div>
        <div className='news-item' onClick={() => { getDetail(11) }}>
          <img className='news-img' src={require('../../static/news-2.png')} alt='' />
          <div className='news-tit'>Worksite Safety Solution</div>
          <div className='news-txt'>Create and China Railway First Group formally launched the implementation of construction site safety monitoring system</div>
        </div>
        <div className='news-item' onClick={() => { getDetail(12) }}>
          <img className='news-img' src={require('../../static/news-3.png')} alt='' />
          <div className='news-tit'> Fleet Management System</div>
          <div className='news-txt'>Create and UPS reached a memorandum of understanding on the development and deployment of a fleet management system</div>
        </div>

      </div>
	<div className='dot'>
	  <Link to='/news'>
    <img className='go-img' src={require('../../static/go.png')} alt='' />
	  </Link>
	  {/* <div></div>
	  <div></div>
	  <div></div> */}
	</div>

      <Client />

    </div>
  )
}
