import React from 'react'
import './index.scss'
import Title from '../Title'

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, FreeMode } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

export default function Client() {
    return (
        <div className='clinet-container'>
            <Title title='Clients' />

            <div className='client'>
                <img className='before swiper-button-prev' src={require('../../static/before.png')} alt='' />

                <Swiper
                    className='client-box'
                    slidesPerView={4}
                    spaceBetween={30}
                    freeMode={true}
                    loop={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }}
                    modules={[Navigation, Autoplay, FreeMode]}
                >
                    <SwiperSlide className='swiper-side-item-class' style={{width:"200px"}}>
                        <img  className='client-img' src={require('../../static/71.jpg')} alt='' />
                    </SwiperSlide>
                    <SwiperSlide className='swiper-side-item-class'>
                        <img  className='client-img' src={require('../../static/72.jpg')} alt='' />
                    </SwiperSlide>
                    <SwiperSlide className='swiper-side-item-class'>
                        <img  className='client-img' src={require('../../static/73.jpg')} alt='' />
                    </SwiperSlide>
                    <SwiperSlide className='swiper-side-item-class'>
                        <img  className='client-img' src={require('../../static/74.jpg')} alt='' />
                    </SwiperSlide>
                    <SwiperSlide className='swiper-side-item-class'>
                        <img  className='client-img' src={require('../../static/75.jpg')} alt='' />
                        
                    </SwiperSlide>
                    <SwiperSlide className='swiper-side-item-class'>
                        <img className='client-img' src={require('../../static/76.jpg')} alt='' />    
                    </SwiperSlide>
                    <SwiperSlide className='swiper-side-item-class'>
                        <img  className='client-img' src={require('../../static/77.jpg')} alt='' />
                        
                    </SwiperSlide>
                    <SwiperSlide className='swiper-side-item-class'>
                        <img  className='client-img' src={require('../../static/78.jpg')} alt='' />
                        
                    </SwiperSlide>
                    <SwiperSlide className='swiper-side-item-class'>
                        <img  className='client-img' src={require('../../static/79.jpg')} alt='' />
                        
                    </SwiperSlide>
                    <SwiperSlide className='swiper-side-item-class'>
                        <img  className='client-img' src={require('../../static/80.jpg')} alt='' />
                        
                    </SwiperSlide>
                    <SwiperSlide className='swiper-side-item-class'>
                        <img  className='client-img' src={require('../../static/81.jpg')} alt='' />
                        
                    </SwiperSlide>
                    <SwiperSlide className='swiper-side-item-class'>
                        <img  className='client-img' src={require('../../static/82.jpg')} alt='' />
                        
                    </SwiperSlide>
                    <SwiperSlide className='swiper-side-item-class'>
                        <img  className='client-img' src={require('../../static/83.jpg')} alt='' />
                        
                    </SwiperSlide>
                    {/* <SwiperSlide className='swiper-side-item-class'>
                        <img  className='client-img10' src={require('../../static/cl-101.png')} alt='' />
                        
                    </SwiperSlide> */}
                </Swiper>
                <img className='after swiper-button-next' src={require('../../static/after.png')} alt='' />
            </div>
        </div>
    )
}
