import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import './index.scss'
import logo from '../../static/logo.png'
import search from '../../static/search.png'
import footBg from '../../static/foot-bg.png'
import Linkedin from '../../static/linkedin.png'
import facebook from '../../static/facebook.png'
import qycode from '../../static/qycode.png'


function Redirect({ to }) {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  });
  return null;
}

export default function Layout() {
  const navigate = useNavigate();
  const location = useLocation()
  useEffect(() => {
    if (location.pathname === '/') {
      navigate('index')
    }
  })

  return (
    <div className='container'>
      <div className='headBox'>
        <img className='logo' src={logo} alt='#' />
        <div className='nav'>
          <span onClick={() => { navigate('index') }}>Home</span>
          <span onClick={() => { navigate('solution?id=10') }}>Solutions</span>
          <span onClick={() => { navigate('product') }}>Product Center</span>
          <span onClick={() => { navigate('about') }}>About us</span>
          <span onClick={() => { navigate('connect') }}>Contact</span>
        </div>
        <img className='search' src={search} alt='#' />
        <span className='language'>EN</span>
      </div>

      <Outlet />

      <div className='footBox'>
        <img className='footBox-bg' src={footBg} alt='#' />
        <img className='foot-logo' src={logo} alt='#' />
        <div className='foot-cont'>
          <div className='foot-nav'>
            <span  onClick={() => { navigate('index') }}>Home</span>
            <span  onClick={() => { navigate('solution?id=10') }}>Solutions</span>
            <span onClick={() => { navigate('product') }}>Product Center</span>
            <span onClick={() => { navigate('about') }}>About us</span>
            <span onClick={() => { navigate('connect') }}>Contact</span>
          </div>

          <div className='conntct-way'>
            <div>Contact: +65 9661 4875</div>
            <div>10 Ubi Crescent #03-58</div>
            <div>Ubi Techpark (Lobby C)</div>
            <div>Singapore 408564</div>
          </div>

        </div>
        <div className='foot-circle'>
        <a  target='_blank' rel="noreferrer" href='https://www.linkedin.com/company/69349070'>  <img className='foot-ins' src={Linkedin} alt='#' /></a>
        </div>
        <div className='foot-circle foot-fb-pos'>
        <a  target='_blank' rel="noreferrer" href='https://www.facebook.com/profile.php?id=100080471415343'><img className='foot-fb' src={facebook} alt='#' /></a>
        </div>
        <img className='foot-qy' src={qycode} alt='#' />

      </div>


    </div>
  )
}
