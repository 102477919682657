import React from 'react'
import './index.scss'
import { ROOT } from '../../utils/request'

export default function Index({ data, type }) {
  data.img = data.productimages.length ?   data.productimages[0] : ''

  return (
    <div className='pro'>
      <div className="production">
        <div className="img-box">
          <img
            alt=""
            src={require('../../static/news/img-bg.png')}
            className="img-bg"
          ></img>
          <img alt=""  src={require("../../static/chanpin/" + data.img)} className="img-item"></img>
        </div>
        <div className="info">
          <div className="info-title">{data.name}</div>
          <div className="info-line"></div>
          <div className="info-cont"  dangerouslySetInnerHTML={{ __html: data.producttext }}></div>
        </div>
      </div>
      {data.mainlyApplicable != '' && (
        <div className="bottom-info">
          <span className="title">
            Mainly applicable：<span className="content" dangerouslySetInnerHTML={{ __html: data.mainlyApplicable }}></span>
          </span>
          
        </div>
      )}
    </div>
  )
}
