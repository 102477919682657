import React, { useState, useEffect } from 'react'
import './index.scss'
import bg from '../../static/connect-bg.png'

import Title from '../../Components/Title'
import axios from "axios";

import http, { ROOT } from '../../utils/request'

var service = axios.create({
  timeout: 40000,
  headers: { "Content-Type": "application/x-www-form-urlencoded" }
});




export default function Connect() {

  let [info, setInfo] = useState({})
  window.scroll(0,0);

  async function getData() {
    // const res = await http.post('/api/index/showContent')
    // if (res.code === 2001) {
    // console.log(res);
    // let ctx = res.data?.contact || {}
    // ctx.addressimage = ROOT + ctx.addressimage
    // setInfo(ctx)
    // }
  }

  useEffect(() => {
    getData()
  }, []);

  let getDetail = id => {
    console.log("123");
    var a = document.getElementById("name").value;
    var b = document.getElementById("contact").value;
    var c = document.getElementById("email").value;
    var d = document.getElementById("message").value;
    console.log(document.getElementById("name").value, document.getElementById("contact").value, document.getElementById("email").value, document.getElementById("message").value);
    if (a == '') {
      alert("Please enter your name")
    } else if (b == '') {
      alert('Please enter your contact')
    } else if (c == '') {
      alert("Please enter your email")
    } else if (d == '') {
      alert('Please enter your massage')
    } else {
      //   const url='https://gw.zwrjkj.cn/index.php/api/index/submitMessage';
      //   const param={
      //     name:a,
      //     contact:b,
      //     email:c,
      //     message:d 
      //   }
      //   return new Promise((resolve, reject) => {
      //     service({
      //         method: "POST",
      //         // headers: {
      //         //     "Content-Type": "application/json",
      //         //     "Access-Control-Allow-Origin": "*",
      //         //     "token": sessionStorage.getItem('token')
      //         // },
      //         url,
      //         data: {
      //           name:a,
      //           contact:b,
      //           email:c,
      //           message:d 
      //         },
      //         dataType: 'json',
      //     })
      //         .then(res => {
      //            console.log(res);
      //         })
      //         .catch(err => {
      //             reject(err.message);
      //         });
      // });
      http.post(ROOT+'/api/index/submitMessage', {
        name: a,
        contact: b,
        email: c,
        message: d

      }).then(res => {
      console.log(res);
      document.getElementById("name").value="";
     document.getElementById("contact").value="";
     document.getElementById("email").value="";
     document.getElementById("message").value="";
     document.getElementById("tip").style.display="block";
      alert("sucess");
        if (res.code === 2001) {
         
          console.log(res.data);
          // let txt = res.data.articlecontent
          // setTxt(txt)
        }
      })
    }
  }

  return (
    <div className='connect'>
      <div className='top-cont'>
        <img className='common-banner' src={bg} alt='#' />
        <div className='common-title'>Contact us</div>
      </div>

      <Title title='Contact us' />

      <div className='map'>
        <img className='map-img-bg' src={require('../../static/lunbo/address.png')} alt='#' />

      </div>

      <div className='addressbox'>

        <div className='addressbox-item'>
          <div>
            <div className='title'>Address:</div>
            <div className='cont1'>Create Technologies Pte Ltd10 Ubi Cres,#03-58,Ubi Techpark(Lobby C),Singapore 408564. </div>
          </div>
        </div>

        <div className='addressbox-item'>
          <div>
            <div className='title'>Contact:</div>
            {/* <div className='cont'>{info.lianxiren}</div> */}
            <div className='cont'>+65 9661 4875</div>
          </div>

          <div>
            <div className='title'>How to find us:</div>
            {/* <div className='cont'>{info.address}</div> */}
            <div className='cont'>Create Technologies Pte Ltd<br />
              10 Ubi Cres,#03-58,Ubi Techpark(Lobby C),Singapore 408564</div>
          </div>
          <div>
            <div className='title'>Email address:</div>
            {/* <div className='cont'>{info.email}</div> */}
            <div className='cont'>sales@create-tech.com.sg</div>
          </div>
          <div>
            <div className='title'>whatsapp:</div>
            {/* <div className='cont'>{info.phone}</div> */}
            <div className='cont'>+65 96614875</div>
          </div>
        </div>

        

        <div className='share'>
          <a target='_blank' rel="noreferrer" href='https://www.linkedin.com/company/create-tech/?viewAsMember=true'>
            <img className='share-img' src={require('../../static/s-1.png')} alt='#' />
          </a>
          {/* <img className='share-img' src={require('../../static/s-2.png')} alt='#' />
          <img className='share-img' src={require('../../static/s-3.png')} alt='#' />
          <img className='share-img' src={require('../../static/s-4.png')} alt='#' />
          <img className='share-img' src={require('../../static/s-5.png')} alt='#' /> */}

        </div>

      </div>

      <div className='form'>
        <img className='left-img' src={require('../../static/aaa.png')} alt='#' />
        <div className='formbox'>
          <div className='title'>Leave a message online</div>
          <div className='ipt-1'>
            <div className='ipt-1-item'>
              <div className='ipt-title'>Customer name</div>
              <input placeholder='Please enter your name' id='name' className='ipt-item'></input>
            </div>
            <div className='ipt-1-item'>
              <div className='ipt-title'>Contact</div>
              <input placeholder='Please enter your contact' id='contact' className='ipt-item'></input>
            </div>
            <div className='ipt-1-item'>
              <div className='ipt-title'>Email</div>
              <input placeholder='Please enter your email' id='email' className='ipt-item'></input>
            </div>
          </div>

          <div className='ipt-1'>
            <div className='ipt-1-item'>
              <div className='ipt-title'>Massage</div>
              <textarea placeholder='Please enter your massage' id='message' className='ipt-item ipt-textarea'></textarea>
            </div>
          </div>
          <div id='tip' className='aob'>The message has beed sent.</div>

          <div className='btn-submit' onClick={() => { getDetail(1) }}>
            Submit
          </div>

        </div>
      </div>

    </div>
  )
}

